import React from "react"
import styled from "styled-components"
import OurOffer from "../components/Organisms/StaticSections/OurOffer"
import Offer from "../components/Organisms/Offer/Offer"
import { WithIntl } from "../components/Atoms/withTranslate"
import MyWashProducer from "../components/Organisms/StaticSections/MyWashProducer"
import WhatIsContactlessCarWash from "../components/Organisms/StaticSections/WhatIsContactlessCarWash"
import BenefitsOfContactlessCarWashes from "../components/Organisms/StaticSections/BenefitsOfContactlessCarWashes"
import BenefitsForDrivers from "../components/Organisms/StaticSections/BenefitsForDrivers"
import CTABanner from "../components/Organisms/CTABanner/CTABanner"
import SEO from "../components/seo"
import { localize } from "../components/Atoms/Language"

const StyledOffer = styled(Offer)`
  margin: 80px 0 160px;
`

const ContactlessCarWashes = ({ language }) => (
  <>
    <SEO
      lang={language}
      title={localize("251")}
      description={localize("252")}
    />
    <div className="container">
      <OurOffer language={language}/>
      <StyledOffer language={language}/>
      <MyWashProducer/>
      <WhatIsContactlessCarWash/>
      <BenefitsOfContactlessCarWashes/>
      <BenefitsForDrivers/>
      <CTABanner language={language}/>
    </div>
  </>
)

export default WithIntl()(ContactlessCarWashes)
