import React from "react"
import styled from "styled-components"
import { GlobalColors } from "../../../assets/styles/GlobalVariables"
import { localize } from "../../Atoms/Language"

const StyledParagraph = styled.p`
  color: ${GlobalColors.gray600};
`

const WhatIsContactlessCarWash = () => (
  <>
    <h3 className="header4">{localize("242")}</h3>
    <StyledParagraph className="body">{localize("243")}</StyledParagraph>
    <StyledParagraph className="body">{localize("244")}</StyledParagraph>
    <StyledParagraph className="body">{localize("245")}</StyledParagraph>
    <StyledParagraph className="body">{localize("246")}</StyledParagraph>
  </>
)

export default WhatIsContactlessCarWash
