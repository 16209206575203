import React from "react"
import styled from "styled-components"
import { GlobalColors } from "../../../assets/styles/GlobalVariables"
import { localize } from "../../Atoms/Language"

const StyledParagraph = styled.p`
  color: ${GlobalColors.gray600};
  margin-bottom: 160px;
`

const BenefitsForDrivers = () => (
  <>
    <h4 className="header4">{localize("249")}</h4>
    <StyledParagraph className="body">{localize("250")}</StyledParagraph>
  </>
)

export default BenefitsForDrivers
