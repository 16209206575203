import React from "react"
import styled from "styled-components"
import ImageWithText from "../../Molecules/ImageWithText/ImageWithText"
import { graphql, useStaticQuery } from "gatsby"
import LocalizedLink from "../../Atoms/LocalizedLink"
import { localize } from "../../Atoms/Language"

const imageQuery = graphql`
  {
    file(name: { eq: "offer1" }) {
      childImageSharp {
        fixed(width: 540){
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

const StyledWrapper = styled.section`
  margin: 80px 0 0;
`

const StyledHeader = styled.h1`
  letter-spacing: -1.1px;
`

const StyledLink = styled(LocalizedLink)`
  font-weight: 700;
  color: inherit;
  text-decoration: none;
`

const OurOffer = ({language}) => {

  const data = useStaticQuery(imageQuery)

  return (
    <StyledWrapper>
      <ImageWithText fixed={data.file.childImageSharp.fixed} imageAltText={localize("2") + "MyWash"}>
        <StyledHeader className="header2">{localize("226")}</StyledHeader>
        <p className="body">{localize("227")}</p>
        <p className="body">{localize("228")} <StyledLink language={language} to="/myjnie-kontenerowe">{localize("229")}</StyledLink>, <StyledLink language={language} to="/myjnie-modulowe">{localize("230")}</StyledLink>, <StyledLink language={language} to="/rodzaje-wiat">{localize("231")}</StyledLink>, <StyledLink language={language} to="/akcesoria">{localize("232")}</StyledLink>, <StyledLink language={language} to="/chemia">{localize("233")}</StyledLink>, <StyledLink language={language} to="/czesci-do-myjni">{localize("234")}.</StyledLink></p>
        <p className="body">{localize("235")}</p>
      </ImageWithText>
    </StyledWrapper>
  )
}

export default OurOffer
