import React from "react"
import styled from "styled-components"
import ImageWithText from "../../Molecules/ImageWithText/ImageWithText"
import { graphql, useStaticQuery } from "gatsby"
import { GlobalColors } from "../../../assets/styles/GlobalVariables"
import { localize } from "../../Atoms/Language"

const imageQuery = graphql`
  {
    file(name: { eq: "offer2" }) {
      childImageSharp {
        fixed(height: 400){
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

const StyledImageWithText = styled(ImageWithText)`
  align-items: center;
`

const StyledTextWrapper = styled.div`
  color: ${GlobalColors.gray600};
  margin: 80px auto;
`

const MyWashProducer = () => {

  const data = useStaticQuery(imageQuery)

  return (
    <>
      <StyledImageWithText isRevert imageAltText={"MyWash" + localize("13")} fixed={data.file.childImageSharp.fixed}>
        <h2 className="header3">{localize("236")}</h2>
        <p className="body">{localize("237")}</p>
      </StyledImageWithText>
      <StyledTextWrapper>
        <p className="body">{localize("238")}</p>
        <p className="body">{localize("239")}</p>
        <p className="body">{localize("240")}</p>

        <p className="body">{localize("241")}</p>
      </StyledTextWrapper>
    </>
  )
}

export default MyWashProducer
