import React from "react"
import styled from "styled-components"
import ImageWithText from "../../Molecules/ImageWithText/ImageWithText"
import { graphql, useStaticQuery } from "gatsby"
import { localize } from "../../Atoms/Language"

const imageQuery = graphql`
  {
    file(name: { eq: "offer3" }) {
      childImageSharp {
        fixed(width: 540, height: 400){
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

const StyledImageWithText = styled(ImageWithText)`
  align-items: center;
`

const StyledList = styled.ul`
  margin-left: 20px;
`

const BenefitsOfContactlessCarWashes = () => {

  const data = useStaticQuery(imageQuery)

  return (
    <StyledImageWithText className="mt8 mb16" imageAltText={localize("247")} fixed={data.file.childImageSharp.fixed}>
      <h3 className="header3">{localize("247")}</h3>
      <p className="body">{localize("248")}</p>
      <StyledList>
        <li className="gray600">{localize("351")}</li>
        <li className="gray600">{localize("352")}</li>
        <li className="gray600">{localize("353")}</li>
        <li className="gray600">{localize("354")}</li>
      </StyledList>
    </StyledImageWithText>
  )
}

export default BenefitsOfContactlessCarWashes
